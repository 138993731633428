<template>
    <div class="Awarpblue Awarp ">
        <dashCard class="energykanbanBox2" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">当前碳排放总览</template>
            <template slot="aside"></template>

            <div class="card-content1">
                <div class="aside-item">
                    <div class="aside-item-title">本期累计碳排放量(t)</div>
                    <div class="aside-item-desc">
                        {{ detailInfo.value1 }}
                        <font>/{{ detailInfo.value2 }}</font>
                    </div>
                    <div class="aside-item-desc1">
                        同期总碳排
                        <font>{{ detailInfo.value3 }}t</font>
                    </div>
                </div>
                <div class="chart-wrap">
                    <chartBoard ref="chart1" :option="chartOption1"/>
                </div>

            </div>
            <div class="card-content2">
                <div class="list-header">
                    <span>公司名称</span>
                    <span>total</span>
                </div>
                <ul class="list-content">
                    <li class="list-item" v-for="(item,idx) in detailInfo.list" :key="idx">
                        <span>{{ item.name }}</span>
                        <span>{{ item.value }}</span>
                    </li>
                </ul>
            </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <energykanbanBox2  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    export default {
        name: 'energykanbanBox2',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
            chartBoard: () => import('@/component/chartBoard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                } 
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                        var dom = this.$refs['chart1']
                        if(!!dom) {
                            dom.handleWindowResize()
                        }
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中
                searchObj: {},
                chartOption1: {},
                detailInfo: {
                    chart1: {},
                },
            }
        },
        computed: {},
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width  
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },

 
        // 改变类型
        changeType(type) {
            this.searchObj.TYPE = type
            this.getData()
        },
	    openChange(status) {
            this.panelOpen = status
	    },
        // 年份弹窗关闭
	    panelChange(value){
	      this.searchObj.VALUE = value;
	      this.panelOpen = false;
          this.getData()
	    },
        onChange(value) {
            console.log("onChange",value,this.searchObj)
            this.getData()
            this.$forceUpdate()
        },
        // 切换项目
        handleChange(value) {
            this.getData()
        },
        //每月报警预警数
        getData() {
            this.isLoading = true
            this.detailInfo = {
                value1: 400,
                value2: 105.33,
                value3: 315,
                list: [
                    {
                        name:"公司名称一",
                        value: 405,
                    },
                    {
                        name:"公司名称二",
                        value: 305,
                    },
                    {
                        name:"公司名称三",
                        value: 400,
                    },
                    {
                        name:"公司名称四",
                        value: 355,
                    },
                    {
                        name:"公司名称五",
                        value: 375,
                    },
                ]
            }
            var chart1 = {
                percent: 26.33
            }
            this.detailInfo.chart1 = chart1
            this.initEchart()
            this.isLoading = false
        },
		initEchart() {     
            var {percent} = this.detailInfo.chart1
            var option = {
                animation:false,
                layoutAnimation:false,
                color: ["#DBDBDB","#FF7300"],
                tooltip: {
                    trigger: 'item'
                },
                angleAxis: {
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    min: 0,
                    max: 6.666,
                    // boundaryGap: ['0', '10'],
                    startAngle: 180,
                    endAngle: 270,
                },
                radiusAxis: {
                    type: 'category',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    data: ['a', 'b', 'c'],
                    z: 10,
                },
                polar: [
                    {
                //     center: ['100%', '100%'], //中心点位置
                //     radius: '1%' //图形大小
                }
            ],
                series: [
                    // 背景
                    {
                        animation: false,
                        name: '负载率',
                        type: 'gauge',
                        radius:'100%',
                        center: ['50%', '50%'],
                        splitNumber: 12,
                        startAngle: 180, //开始角度 左侧角度
                        endAngle: 0, //结束角度 右侧
                        // 展示当前进度
                        progress: {
                            show: true,
                            width: 15,
                            itemStyle: {
                                color: 'rgba(255,255,255,.2)',
                                fontFamily: "ABBvoice_WCNSG_Rg"
                            },
                        },
                        // 仪表盘指针
                        pointer: {
                            show: false,
                        },
                        // 仪表盘轴线相关配置
                        axisLine: {
                            show: false,
                        },
                        // 刻度样式
                        axisTick: {
                            show: false,
                        },
                        // 分隔线样式
                        splitLine: {
                            show: false,
                        },
                        // 刻度标签
                        axisLabel: {
                            show: false,
                        },
                        // 表盘中指针的固定点
                        anchor: {
                            show: false,
                        },
                        detail: {
                            show: false,
                            fontFamily: "ABBvoice_WCNSG_Rg"
                        },
                        data: [
                            {
                                value: 100,
                            },
                        ],
                    },
                    // 内容
                    {
                        name: '',
                        type: 'gauge',
                        radius:'100%',
                        center: ['50%', '50%'],
                        min: 0,
                        max: 100,
                        zlevel: 10,
                        splitNumber: 12,
                        startAngle: 180, //开始角度 左侧角度
                        endAngle: 0, //结束角度 右侧
                        // 展示当前进度
                        progress: {
                            show: true,
                            width: 15,
                            itemStyle: {
                                color: '#94FFE4',
                                fontFamily: "ABBvoice_WCNSG_Rg"

                            },
                        },
                        // 仪表盘指针
                        pointer: {
                            show: false,
                        },
                        // 仪表盘轴线相关配置
                        axisLine: {
                            show: false,
                        },
                        // 刻度样式
                        axisTick: {
                            show: false,
                        },
                        // 分隔线样式
                        splitLine: {
                            show: false,
                        },
                        splitNumber: 1,
                        // 刻度标签
                        axisLabel: {
                            show: false,
                        },
                        // 表盘中指针的固定点
                        anchor: {
                            show: false,
                        },
                        title: {
                            show: true,
                            offsetCenter: [0, "-10%"], // x, y，单位px
                            textStyle: {
                            color: "#ddd",
                            fontFamily: "ABBvoice_WCNSG_Rg"
                            // fontSize: 50,
                            },
                        },
                        detail: {
                            borderRadius: 2,
                            offsetCenter: ['0%', '-35%'],
                            fontSize: 30,
                            fontWeight: 'bolder',
                            formatter: "{value}%",
                            color: '#FFFFFF',
                            fontFamily: "ABBvoice_WCNSG_Rg"
                        },
                        data: [
                            {
                                value: percent,
                                name: '使用率'
                            },
                        ],
                    },

                ],
            };








            
            this.updateChart('chart1', 'chartOption1', option)
        },
        updateChart: function (refName, optionName, option = {}) {
            /* 渲染echart图 */
            if (!optionName) return
            this[optionName] = option
            setTimeout(() => {
                this.$refs[refName].updateChartView()
            }, 500)
        },
            
        },
        mounted() {
            this.getOperateWidth()
            if(!this.option.visible) {
                this.getData()
            }
        }
    }
    </script>
    
<style lang="less" scoped>
.energykanbanBox2 {
    .card-content1 {
        flex: 1;
        display: flex;
        height: 96px;
        .aside-item {
            width: 170px;
            .aside-item-title {
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                color: #ffffff;
                line-height: 22px;
            }
            .aside-item-desc {
                font-size: 36px;
                font-weight: 400;
                color: #ffffff;
                line-height: 44px;
                font {
                    font-size: 12px;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 20px;
                }

            }
            .aside-item-desc1 {
                margin-top: 8px;
                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
                line-height: 20px;
            }
        }
        .chart-wrap {
            flex: 1;
            height: 100%;
            // background: orange;
            div{

                // width: 180px;
                height: 180px;
            }
        }
    }
    .card-content2 {
        height: 250px;
        width: 100%;
        // transform: translateY(-50px);
        .list-header {
            height: 34px;
            background: rgba(255,255,255,.1);
            border-radius: 2px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 11px;
            box-sizing: border-box;
            span {
                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
                line-height: 17px;
            }
        }
        .list-content {
            height: 200px;
            overflow: auto;
            .list-item {
                height: 34px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 11px;
                box-sizing: border-box;
                span {
                    font-size: 12px;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 17px;
                }
            }


        }
    }
                /* 整个滚动条 */
            ::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }

            /* 滚动条上的滚动滑块 */
            ::-webkit-scrollbar-thumb {
                background-color: #4c7dec;
                /* 关键代码 */
                // background-image: -webkit-linear-gradient(45deg,
                //         rgba(255, 255, 255, 0.4) 25%,
                //         transparent 25%,
                //         transparent 50%,
                //         rgba(255, 255, 255, 0.4) 50%,
                //         rgba(255, 255, 255, 0.4) 75%,
                //         transparent 75%,
                //         transparent);
                border-radius: 32px;
            }

            /* 滚动条轨道 */
            ::-webkit-scrollbar-track {
                background-color: transparent;
                border-radius: 32px;
            }
}


</style>